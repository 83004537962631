@font-face {
  font-family: "Gilroy";
  src:
    url("../fonts/Gilroy-Regular.woff2") format("woff2"),
    url("../fonts/Gilroy-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src:
    url("../fonts/Gilroy-Medium.woff2") format("woff2"),
    url("../fonts/Gilroy-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-Bold";
  src:
    url("../fonts/Gilroy-Bold.woff2") format("woff2"),
    url("../fonts/Gilroy-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-SemiBold";
  src:
    url("../fonts/Gilroy-SemiBold.woff2") format("woff2"),
    url("../fonts/Gilroy-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Regular.woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Gilroy";
}
body {
  font-family: "Gilroy";
  font-weight: normal;
  font-size: 16px;
  color: #717e94;
  line-height: normal;
}
button {
  cursor: pointer;
  border: none;
}
button:disabled {
  background: #f6f6f6 !important;
}
samp,
font,
li {
  font-family: "Gilroy";
}
p,
.paragraph-item-text {
  font-family: "Gilroy";
  font-weight: normal;
  font-size: 16px;
  color: #717e94;
  line-height: 24px;
  margin: 0 0 15px;
}
a {
  color: #4253f0;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
  color: #4253f0;
}
iframe,
img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  font-family: "Gilroy";
}
h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: normal;
  color: #1d1858;
  margin: 0 0 15px;
}
h2 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: normal;
  color: #1d1858;
  margin: 0 0 15px;
}
h3 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: normal;
  color: #1d1858;
  margin: 0 0 15px;
}
h4 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: normal;
  color: #1d1858;
  margin: 0 0 15px;
}
h5 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  color: #1d1858;
  margin: 0 0 15px;
}
h6 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: #1d1858;
  margin: 0 0 15px;
}

.height-40 {
  height: 40px;
}
.full-width {
  width: 100%;
}

.btn-style.loading-style .MuiCircularProgress-root {
  height: 25px !important;
  width: 25px !important;
}

.btn-style {
  letter-spacing: 0;
  line-height: 24px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  padding: 11px 16px;
  background: #4253f0;
  border: solid 1px #4253f0;
  border-radius: 4px;
  display: inline-block;
}

.btn-style.login-back {
  padding: 6px 10px;
  margin-right: 24px;
}

.btn-style:disabled,
.btn-style:disabled:hover {
  background: #f4f5f8;
  color: #7a8ba0;
  border: none;
}

.add-existing-employee
  .form-item
  .input-box
  .MuiInputBase-root.MuiOutlinedInput-root
  .MuiSelect-select.MuiSelect-outlined {
  text-align: start;
}

.employees-edit-icon {
  color: #333645;
}

.employees-edit-btn {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.employees-edit-btn:hover {
  background: #f5f5f6 !important;
  border-radius: 3px;
}

.small-btn.btn-style {
  line-height: normal;
  font-size: 14px;
  padding: 9px 16px;
}
.btn-style:hover {
  color: #4253f0;
  background: #fff;
  border: solid 1px #4253f0;
  text-decoration: none;
}

.btn-style:hover:not(.no-hover-effect) svg path {
  fill: #4253f0;
}

.btn-style.add-new-employee svg {
  margin-right: 20px;
}

.btn-style.add-new-employee {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  position: sticky;
  bottom: 0;
}

.btn-style.btn-outline {
  background: #ebf0fe;
  border: 1px solid #4253f0;
  color: #4253f0;
  cursor: pointer;
}
.btn-style.btn-outline:hover {
  background: #4253f0;
  border: 1px solid #4253f0;
  color: #fff;
  cursor: pointer;
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
}

.login-logo button {
  background: transparent;
}

.login-logo {
  position: absolute;
  left: 60px;
  top: 40px;
  z-index: 2;
}
.login-logo img {
  max-width: 48px;
}
.login-main-sec {
  position: relative;
  padding: 25px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
}
.login-left-sec.sign-up-page {
  padding: 0px 25px 35px;
}
.login-left-sec {
  width: 50%;
  padding: 140px 25px 35px;
}
.login-right-sec {
  width: 50%;
  padding: 140px 25px 95px;
  background: #4253f0;
  border-radius: 16px;
  height: 94vh;
}
.login-left-sec .inner-sec {
  width: 100%;
  max-width: 425px;
  margin: 0 auto;
  display: block;
}
.email-confirmation {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.email-confirmation-container {
  max-width: 519px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.email-confirmation-close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.email-confirmation-close svg path {
  fill: #5d7189;
}
.email-confirmation-close svg {
  cursor: pointer;
  width: 11px;
  height: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email-confirmation-title {
  font-family: Gilroy;
  font-size: 32px;
  font-weight: 600;
  color: #141414;
  margin: 16px 0;
}
.email-confirmation-subtitle b {
  color: #4253f0;
}
.email-confirmation-subtitle {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  text-align: center;
}
.email-confirmation-divider {
  width: 100%;
  max-width: 500px;
  height: 1px;
  background: #eaeaea;
  margin: 24px 0;
}
.email-confirmation-footer div {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
}
.email-confirmation-footer {
  display: flex;
  align-items: center;
}
.email-confirmation-footer button {
  background: none;
  margin-left: 4px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #4253f0;
}
.email-confirmation-footer button:hover {
  text-decoration: underline;
}
.login-right-sec .inner-sec {
  width: 100%;
  max-width: 525px;
  margin: 0 auto;
  display: block;
}
.login-left-sec h1 {
  margin: 0 0 4px;
  font-size: 32px;
}
.login-left-sec h1 + p {
  margin: 0;
}
.login-options {
  margin: 33px 0 31px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
}
.login-options button {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid #eef0f2;
  border-radius: 8px;
  padding: 13px 16px;
  width: calc(50% - 8px);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #1d1858;
  transition: all 0.35s ease-in-out;
  background: transparent;
}
.login-options button:hover {
  border: 1px solid #1d1858;
}
.login-options button img {
  margin-right: 13px;
  max-width: 21px;
}
.login-options button + button {
  margin-left: 16px;
}
.input-box .input-mask {
  width: 100%;
}
.or-text {
  position: relative;
  display: block;
  text-align: center;
  margin: 0 0 30px;
}
.or-text.sign-up-form {
  margin: 0 0 25px;
}
.or-text:before {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  border-top: 0.5px solid #eef0f2;
  content: "";
}
.or-text span {
  background: #fff;
  position: relative;
  z-index: 2;
  padding: 0 10px;
  line-height: normal;
  display: inline-block;
  margin: 0 auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #717e94;
}
form.login-form .input-items {
  display: block;
  margin: 0 0 24px;
}
form.login-form.sign-up-form .input-items {
  margin-bottom: 12px;
}
form.login-form .input-items label {
  display: block;
  margin: 0 0 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #8d98a9;
}
form.login-form .input-items input {
  padding: 13px 16px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #eef0f2;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1d1858;
  outline: none;
  transition: all 0.35s ease-in-out;
}
form.login-form .input-items input:focus {
  border: 1px solid #7b87f5;
  box-shadow: 0px 0px 0px 4px rgba(64, 139, 250, 0.15);
}

form.login-form .agree-items {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: 0 0 24px;
}
form.login-form .agree-items input {
  margin-right: 11px;
  width: 20px;
  height: 20px;
}
form.login-form .agree-items label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1d1858;
}
.submit-btn {
  margin: 0 0 32px;
}
.submit-btn.sign-up-form {
  margin: 0 0 12px;
}
.submit-btn input {
  width: 100%;
  text-align: center;
  padding: 11px 16px;
  background: #4253f0;
  border: solid 1px #4253f0;
  outline: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}
.submit-btn input:hover {
  background: #fff;
  border: solid 1px #4253f0;
  color: #4253f0;
}
.have-account-sec,
.have-account-sec button {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1d1858;
}
.have-account-sec button {
  background: transparent;
  color: #4253f0;
}
.login-right-sec h2 {
  color: #fff;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  margin: 0 0 3px;
  max-width: 430px;
}
.login-right-sec p {
  color: #fff;
  margin: 0 0 60px;
}
.login-marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 25px;
  overflow-x: hidden;
  margin: 90px 0 0;
}

.track {
  position: absolute;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 20s linear infinite;
}
.track img {
  margin: 0 35px;
  max-height: 24px;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
.login-marquee:before {
  position: absolute;
  content: "";
  width: 120px;
  height: 24px;
  left: 0;
  top: 0px;
  background: linear-gradient(270deg, #4253f0 5.36%, rgba(66, 83, 240, 0) 74.5%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: 1;
}
.login-marquee:after {
  position: absolute;
  content: "";
  width: 120px;
  height: 24px;
  right: 0px;
  top: 0px;
  background: linear-gradient(270deg, #4253f0 5.36%, rgba(66, 83, 240, 0) 74.5%);
}
.copyright-sec {
  position: absolute;
  left: 0;
  bottom: 10px;
  z-index: 2;
  width: 50%;
  text-align: center;
}
.copyright-sec p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #717e94;
}

.main-outer-sec {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.sidebar-sec {
  width: 290px;
  padding: 0 24px;
  border-right: 1px solid #eef0f2;
  top: 0;
  position: sticky;
  height: 100vh;
  transition: 0.2s;
}

.sidebar-menus-sec {
  min-height: calc(100% - 110px);
  padding-top: 15px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
}
.sidebar-bottom-sec-collapse {
  display: flex;
  width: 28px;
  height: 28px;
  border: 2px solid #eef0f2;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -14px;
  top: calc(50% - 14px);
  background: white;
  cursor: pointer;
}
.sidebar-bottom-sec-collapse svg path {
  stroke: #4253f0;
}

.sidebar-sec:hover .sidebar-bottom-sec-collapse {
  background: #ebf0fe;
  border: 2px solid #4253f0;
}
.sidebar-bottom-sec {
  width: 100%;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-align-self: flex-end;
  align-self: flex-end;
}
.sidebar-bottom-sec .sidebar-menus {
  margin: 0;
}
.main-logo {
  padding: 21px 0 45px 60px;
  position: relative;
  height: 64px;
}
.main-logo .logo-item {
  position: absolute;
  left: 6px;
  top: 22px;
}
.main-logo .logo-item img {
  max-width: 48px;
}
.main-logo h6 {
  margin: 2px 0 4px;
  font-weight: 500;
  font-size: 18px;
  line-height: normal;
  color: #1d1858;
}
.main-logo .header-mail {
  display: block;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: #717e94;
}
.three-dots {
  font-size: 20px;
  font-weight: 600;
  padding-top: 10px;
  padding-left: 10px;
}
.company-add-modal .form-item label {
  text-align: left;
}
.MuiChip-root {
  border-radius: 4px !important;
  background-color: #eaf3ff !important;
  font-size: 14px !important;
  font-weight: 400;
  color: #4253f0 !important;
}
.sidebar-menus {
  margin: 0 0 25px;
  padding: 0;
  width: 100%;
}
.sidebar-menus h6 {
  padding: 0 0 8px 17px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #8d98a9;
  width: 100%;
}

.sidebar-menus ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}
.sidebar-menus ul li {
  display: block;
  margin-bottom: 8px;
}
.sidebar-menus ul li a,
.sidebar-menus ul li button {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border-radius: 12px;
  color: #717e94;
  text-decoration: none;
  padding: 16px;
  transition: all 0.35s ease-in-out;
  height: 52px;
}
.sidebar-menus ul li a,
.sidebar-menus ul li button {
  gap: 13px;
}
.sidebar-menus ul li a img,
.sidebar-menus ul li button img {
  filter: grayscale(0.81);
  transition: all 0.35s ease-in-out;
  max-width: 20px;
}
.sidebar-menus ul li a.active,
.sidebar-menus ul li a:hover,
.sidebar-menus ul li button.active,
.sidebar-menus ul li button:hover {
  background: #ebf0fe;
  color: #4253f0;
  font-weight: 600;
}
.sidebar-menus ul li button.active,
.sidebar-menus ul li button:hover,
.sidebar-menus ul li button {
  width: 100%;
}
.sidebar-menus ul li button {
  background: transparent;
}
.sidebar-menus ul li a,
.sidebar-menus ul li button {
  gap: 13px;
}
.sidebar-menus ul li a svg,
.sidebar-menus ul li button svg {
  max-width: 20px;
  transition: all 0.35s ease-in-out;
}
.sidebar-menus ul li a.active svg path,
.sidebar-menus ul li a:hover svg path,
.sidebar-menus ul li button.active svg path,
.sidebar-menus ul li button:hover svg path {
  stroke: #4253f0;
}
.sidebar-menus ul li a.active img,
.sidebar-menus ul li a:hover img,
.sidebar-menus ul li button.active img,
.sidebar-menus ul li button:hover img {
  filter: grayscale(0);
}
.header-sec {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #eef0f2;
  width: calc(100% + 48px);
  margin: 0 -24px;
  top: 0;
  position: sticky;
  z-index: 101;
  background: white;
}
.header-sec-bread-crumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header-sec-bread-crumb {
  padding: 4px 16px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  background: #f6f6f6;
  cursor: pointer;
  color: #717e94;
}
.header-sec-bread-crumb:hover {
  color: #1d1858;
}
.header-sec-bread-crumb.active {
  color: #1d1858;
  cursor: default;
}
.header-sec-bread-crumb-arrow {
  margin: 0px 8px;
}
.header-sec-bread-crumb-wrapper {
  display: flex;
  align-items: center;
}
.header-logout-btn {
  position: absolute;
  right: 24px;
  top: 70px;
  width: 120px;
  border: 1px solid #eef0f2;
  padding: 8px;
  background: white;
  border-radius: 8px;
}
.header-logout-btn button {
  width: 100%;
  background: transparent;
  min-height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #d4d9de;
  border-radius: 8px;
  padding: 8px;
}
.header-logout-btn button:hover {
  background: #4253f0;
  color: white;
}
.header-logout-btn button:hover .header-logout-icon {
  color: white;
}
.header-logout-icon {
  color: #717e94;
  margin-right: 8px;
}
.search-box {
  width: 60%;
  max-width: 400px;
  padding: 12px 16px;
  background: #f6f6f6;
  border: 2px solid #f6f6f6;
  border-radius: 12px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.search-box img {
  width: 16px;
  min-width: 16px;
}

.search-box.note-container {
  height: 38px;
  margin-top: 8px;
  margin-bottom: 0;
  max-width: 100%;
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 5px;
}

.search-box.note-container input {
  width: 100%;
  color: #344054;
  padding-left: 0px;
  font-weight: 100;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}

.employees-profile-img,
.employees-profile-img .MuiSvgIcon-root {
  width: 56px !important;
  height: 56px !important;
}
.employees-profile-img .MuiAvatar-img,
.employees-profile-img .MuiSvgIcon-root {
  /* object-fit: contain; */
}

.employees-btn {
  margin-right: 0 !important;
  height: 48px;
  min-width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaderboard-btn {
  display: flex;
  align-items: center;
}

.leaderboard-btn-icon {
  margin-right: 8px;
}

.leaderboard-btn-icon path {
  fill: #ffffff;
}
.total-statistic-block{
  display: flex;
}
.total-statistic-block-icon {
  min-width: 52px;
  height: 52px;
  background: #2988ff1a;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}

.total-statistic-block-vs {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #717e94;
  white-space: nowrap;
}

.total-statistic-block-vs,
.total-statistic-block-vs-item {
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.total-statistic-block-vs-item {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.total-statistic-block-vs-item svg {
  margin-right: 4px;
}

.total-statistic-block-vs-item.green {
  color: #16ab6d;
}

.total-statistic-block-vs-item.red {
  color: #fc5371;
}

.leaderboard-ranked {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.leaderboard-ranked-last {
  display: flex;
  align-items: center;
}

.leaderboard-ranked-last.red {
  color: #fc5371;
}

.leaderboard-ranked-last.green {
  color: #16ab6d;
}

.total-statistic-block-text h5 {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #717e94;
  margin: 0 !important;
  margin-bottom: 6.5px !important;
}

.display-by-select,
.display-by-select-wrapper {
  display: flex;
  align-items: center;
}

.display-by-select-date {
  display: flex;
  align-items: center;
  border: 1px solid #eef0f2;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 4px 8px;
  height: 33px;
  border-left: none;
}

.display-by-select p {
  margin-right: 8px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  color: #6b809b;
}

.chart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 20px;
}

g.recharts-cartesian-grid-horizontal line {
  stroke: #eef0f2;
  display: block;
}

.recharts-layer path:first-child {
  stroke: none;
}

g line {
  display: none;
}

.recharts-surface path {
  stroke: #4253f0;
}

.recharts-active-dot {
  width: 11px;
  height: 11px;
}

text {
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  fill: #8d98a9;
}

.recharts-tooltip-label {
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #8d98a9;
}

.recharts-yAxis.yAxis {
  margin-right: 4px;
}

.applications-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.applications-item-content {
  display: flex;
  align-items: center;
}

.applications-item-content img {
  width: 40px;
  height: 40px;
  border-radius: 12px;
}

.applications-item-text {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
}

.applications-item-text div:first-child {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #1d1858;
  margin-bottom: 8px;
}

.applications-item-text div:last-child {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #717e94;
}

.chart-header h4 {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #1d1858;
  margin-bottom: 0;
}

.total-statistic-block-text h2 {
  font-family: Gilroy;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: #1d1858;
  margin: 0 !important;
  margin-bottom: 6.5px !important;
  width: auto;
}

.search-box input {
  width: calc(100% - 16px);
  padding-left: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: #8d98a9;
  border: none;
  outline: none;
  background: none;
}
.content-sec {
  width: calc(100% - 290px);
  padding: 0 24px 24px;
  transition: 0.2s;
}
.header-box-r {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.header-box-r a {
  display: block;
  width: 40px;
  height: 40px;
  background: #f6f6f6;
  border-radius: 100%;
}
.header-box-r button.notification-btn {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid transparent;
}

.header-box-r button.notification-btn:hover,
.header-box-r button.notification-btn.active {
  border: 1px solid #ccc3fd !important;
  background: #f6f4fe;
}

.header-box-r button.notification-btn.activeWithoutBorder {
  border: 1px solid transparent;
  background: #f6f4fe;
}

.header-box-r button.notification-btn:hover svg path,
.header-box-r button.notification-btn.active svg path,
.header-box-r button.notification-btn.activeWithoutBorder svg path {
  stroke: #5a3fe0;
}

.header-box-r button.notification-btn svg {
  width: 20px;
  height: 20px;
}
.multiple-icon-station {
  width: 18px;
  height: 18px;
  box-shadow: 1px 3px 15px 0px #65472b21;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -6px;
  background: #fff;
  border-radius: 50%;
}
.multiple-icon-station svg {
  width: 12px;
  height: 12px;
}
.warning-alert {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
}
.header-box-r button.notification-btn div {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 18px;
  height: 18px;
  background: #fc5371;
  color: white;
  border-radius: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
}
.header-box-r button.notification-btn div p {
  margin: 0;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: #ffffff;
}
.header-box-r button.user-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow-x: hidden;
  margin-left: 24px;
  background: #e9e8e8;
  font-size: 30px;
  line-height: 40px;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-box-r button.user-btn img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.top-title-bar {
  padding: 24px 0;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.title-sec h3 {
  margin: 0;
}
.top-title-bar .title-sec {
  width: 50%;
  white-space: nowrap;
}
.top-title-bar .title-sec h2 {
  margin: 0 0 9px;
}
.top-title-bar .title-sec h5 {
  color: #717e94;
  margin: 0;
}
.top-title-bar .btn-sec {
  width: 50%;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.employees-data-sec {
  border: 1px solid #eef0f2;
  border-radius: 12px;
  padding: 24px;
}
.employees-data-sec table {
  width: 100%;
}

.employees-data-sec table th {
  background: #f6f6f6;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #717e94;
  text-align: center;
  padding: 12px 15px;
  font-family: "Gilroy";
}
.employees-data-sec table th:nth-child(1) {
  width: 27%;
  padding: 12px 15px 12px 24px;
  text-align: left;
}
.employees-data-sec table th:last-child {
  width: 67px;
}
.employees-data-sec table td {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #1d1858;
  font-weight: normal;
  padding: 17px 15px;
  text-align: center;
  border-bottom: 1px solid #eef0f2;
  font-family: "Gilroy";
}
.employees-data-sec .organizations table td.admin-organization {
  padding-left: 0 !important;
}
.employees-data-sec .organizations table td.admin-organization td:nth-child(1),
.employees-data-sec .organizations table td.admin-organization td:nth-child(2) {
  padding-left: 24px !important;
  text-align: start !important;
}
.employees-data-sec table td:nth-child(1) {
  width: 27%;
  padding-left: 15px !important;
  text-align: left;
}
.employees-data-sec table td:last-child button {
  margin: auto !important;
}
.employees-data-sec.organizations table td:nth-child(1),
.employees-data-sec.organizations table th:nth-child(1) {
  width: auto;
}
.btn-style.download-payroll-btn.organizations {
  background: #4253f0;
  border: 1px solid #4253f0;
  color: #fff;
  cursor: pointer;
  padding: 5px 8px;
}
.btn-style.download-payroll-btn.organizations:hover {
  border: 1px solid #d4d9de;
  background: #fff;
  color: #4253f0;
}
.btn-style.download-payroll-btn.organizations:disabled {
  color: #d4d9de;
  border: 1px solid #d4d9de;
}
.employees-data-sec table td:nth-child(1) img,
.payroll-data-table.payroll-table table td:nth-child(1) img {
  position: absolute;
  left: 0 !important;
  top: 0 !important;
  transform: none !important;
  max-width: 36px !important;
}
.employees-data-sec table td:nth-child(1) h6 {
  margin: 0;
  font-weight: 500;
}
.employees-data-sec table td:nth-child(1) span {
  font-size: 12px;
  line-height: 16px;
  color: #717e94;
}
samp.training {
  background: #fef8ec;
  border-radius: 8px;
  color: #f1ae15;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
}
samp.daily {
  background: #e8f7f0;
  border-radius: 8px;
  color: #16ab6d;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
}
samp.weekly {
  background: #ebf0fe;
  border-radius: 8px;
  color: #4253f0;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
}
.pagination {
  padding: 20px 0 0;
}
.pagination ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.pagination ul li {
  margin-left: 24px;
}
.pagination ul li button {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  background: #ffffff;
  border: 1px solid #d4d9de;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  text-decoration: none !important;
}
.pagination ul li button img {
  max-width: 20px;
}
.pagination ul li button:hover,
.pagination ul li button.active {
  background: #f6f6f6;
  border: 1px solid #eef0f2;
}
.sidebar-top-sec {
  width: 100%;
}

.collapsed-danger {
  position: absolute;
  right: -1px;
  top: -3px;
}

.sidebar-menus ul li button.active > svg.danger > path,
.sidebar-menus ul li button:hover > svg.danger > path,
.sidebar-menus ul li button.active > svg.collapsed-danger > path,
.sidebar-menus ul li button:hover > svg.collapsed-danger > path {
  stroke: none !important;
}
.employee-tab-danger {
  gap: 4px;
  display: flex;
  align-items: center;
}
.employees-page-tab .MuiTabs-flexContainer {
  border-bottom: 2px solid #ebecf0;
}
.employees-page-tab {
  border-radius: 8px;
  min-height: 34px !important;
  height: 34px;
  margin-bottom: 40px;
}
.employees-page-tab .MuiTabs-indicator {
  background: #0052cc;
  border-radius: 1px;
}
.employees-page-tab .MuiButtonBase-root.MuiTab-root {
  height: 32px;
  min-height: 32px;
  padding: 0;
  color: #344054;
  text-transform: capitalize;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
}
.employees-page-tab .MuiButtonBase-root.MuiTab-root.Mui-selected {
  color: #0052cc;
}
.mobile-menu-bars {
  display: none;
}
.employees-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(29, 24, 88, 0.3);
}
.popup-overlay-close {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.employees-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 12px;
  width: 90%;
  max-width: 480px;
  max-height: 90%;
  overflow: auto;
}
.upload-gc-inner {
  max-width: 1200px;
}
.upload-gc-top-bar h2 {
  width: 100% !important;
}
.upload-gc-top-bar-container {
  align-items: flex-start !important;
}
.popup-top-bar {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding: 24px 24px 15px;
  border-bottom: 1px solid #eef0f2;
}
.popup-top-bar h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1d1858;
  margin: 0;
  width: calc(100% - 35px);
}
.popup-top-bar a {
  text-decoration: none;
}
.popup-dis-sec.employees-modal {
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.popup-dis-sec {
  padding: 25px 24px;
}
.popup-tabs p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #717e94;
  margin: 0 0 10px;
}
.popup-tabs ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.popup-tabs ul li {
  margin: 0 12px 0 0;
}
.popup-tabs ul li:last-child {
  margin: 0;
}
.popup-tabs ul li button {
  padding: 9px 55px;
  background: #f6f6f6;
  border: solid 1px #f6f6f6;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #717e94;
  display: block;
  transition: all 0.35s ease-in-out;
}
.popup-tabs ul li button:hover,
.popup-tabs ul li button.active {
  background: #ebf0fe;
  color: #4253f0;
  border: 1px solid #7b87f5;
  text-decoration: none;
}
.popup-content-sec.weekly-salary {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}
.add-popup-content-sec,
.popup-content-sec {
  padding: 20px 0 0;
}
#Weekly-data {
  display: block;
}
.form-item {
  margin: 0 0 15px;
}

.form-item.full-width {
  width: 100%;
}
.form-item.percentage label {
  margin-bottom: 8px;
  align-items: center;
  display: flex;
}
.form-item label span.MuiTypography-root,
.form-item label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1d1858;
  font-family: Gilroy !important;
}
.form-item label {
  display: block;
  margin: 0 0 8px;
}
.input-items .MuiTextField-root {
  width: 100%;
}
label.error,
.agree-items .error {
  color: #d32f2f !important;
}
.form-item label.error {
  color: red;
}
.form-item input {
  padding: 9px 16px 9px 16px;
  background: #ffffff;
  width: 100%;
  border: 1px solid #eef0f2;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1d1858;
}

.form-item .MuiInputBase-root.MuiOutlinedInput-root {
  border-radius: 8px;
}

.form-item.add-existing input {
  border: none;
  box-shadow: none;
}

.form-item.add-existing input:focus {
  border: none;
  box-shadow: none;
}

.trial-wrapper {
  top: 0;
  width: 100%;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  background: rgba(247, 212, 139, 0.5);
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #000000;
  flex-wrap: wrap;
}

.trial-wrapper b {
  margin-left: 4px;
}

.check-payment-button {
  color: #4253f0;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-left: 4px;
  cursor: pointer;
}

.trial-button {
  color: #4253f0;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-left: 24px;
  cursor: pointer;
}

.trial-button:hover {
  text-decoration: underline;
}

.trial-ended {
  display: flex;
  width: 100%;
  margin-top: 100px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.trial-ended-title {
  font-family: Gilroy;
  font-size: 25px;
  font-weight: 500;
  line-height: 32px;
}

.trial-ended-subtitle {
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

.trial-ended .trial-button {
  margin-left: 0;
  font-size: 20px;
  margin-top: 24px;
}

.form-item input:focus {
  border: 1px solid #7b87f5;
  outline: none;
  box-shadow: 0px 0px 0px 2px rgba(64, 139, 250, 0.15);
}
.autocomplete-error .MuiOutlinedInput-root {
  border-color: #fc5371 !important;
}
.auto-select-button svg {
  width: 20px;
  height: 20px;
}
.auto-select-button {
  position: sticky;
  bottom: 0;
  height: 40px;
  width: 100%;
  border-top: 1px solid #eef0f2;
  background: #fff;
  color: #4253f0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auto-select-button.skip {
  bottom: 2.5rem;
}
.select-with-search-li {
  height: 40px;
  padding: 8px 16px;
  cursor: pointer;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.select-with-search-li.border-bottom {
  border-bottom: 1px solid #eef0f2;
}
.select-with-search-li:hover,
.select-with-search-li.active {
  background: #f8f8f8;
}
.select-with-search-li-avatar {
  width: 20px !important;
  height: 20px !important;
}
.input-box.fuel {
  width: 90px;
}
.input-box {
  position: relative;
}

.select-helper-text {
  margin-top: -2px !important;
}

.input-box div div fieldset {
  display: none;
}

.input-box div div {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.input-box div p {
  color: red !important;
}

.header-menu-wrapper .MuiPaper-root {
  margin-top: 8px;
  box-shadow: 0px 0px 15px 0px #0522471f;
  border-radius: 8px;
  margin-left: -12px;
}

.header-menu-wrapper .MuiPaper-root .MuiList-root {
  width: 264px;
  padding: 12px;
}

.header-menu-wrapper.notifications .MuiPaper-root {
  margin-top: 16px;
  margin-left: -75px;
}

.header-menu-wrapper.notifications .MuiPaper-root .MuiList-root {
  width: 436px;
  padding: 0;
}

.header-menu-wrapper.notifications .MuiPaper-root {
  max-height: 436px !important;
}

.notifications-menu-header {
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #f3f3f3;
}

.notifications-menu-header h2 {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}

.notifications-menu-header button {
  color: #072d5e;
  background: transparent;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.notifications-menu-header button:hover {
  text-decoration: underline;
  color: #062042;
}

.notifications-menu-empty {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5d7189;
}

.notifications-menu-content {
  padding: 16px 20px;
  max-height: 326px;
  overflow: auto;
}

.notifications-menu-content::-webkit-scrollbar {
  width: 4px;
  margin-left: 8px;
}

.notifications-menu-content::-webkit-scrollbar-thumb {
  background-color: #eaecf0;
  border-radius: 8px;
}

.notifications-menu-content::-webkit-scrollbar-thumb:hover {
  background: #bdbfc4;
}

.notifications-menu-prev {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
}

.notifications-menu-prev h5 {
  color: #1d1858;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin: 0 4px;
}

.notifications-menu-prev div {
  height: 1px;
  background: #f3f3f3;
  width: 29%;
}

.notifications-menu-item {
  display: flex;
  padding: 6px;
  margin-bottom: 6px;
}

.notifications-menu-item-icon {
  margin-right: 8px;
  width: 32px;
  height: 32px;
  background: #f1f1f1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notifications-menu-item-icon .no-read {
  background: #e8f7f0;
}

.notifications-menu-item-text {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  max-width: 340px;
  color: #344054;
}

.notifications-menu-item-time {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #8d98a9;
}

.header-menu-item {
  height: 40px;
}

.redBg {
  background: red;
}

.header-menu-divider {
  height: 1px;
  width: 100%;
  background: #eef0f2;
  margin: 8px 0;
}

.blur-text input {
  color: transparent;
  text-shadow: 0 0 8px #000;
  -webkit-text-fill-color: unset !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiMenu-paper {
  max-height: 334px;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.input-box span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #717e94;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.form-item .input-box span {
  left: 8px;
  z-index: 1;
}
.option-toggle {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.add-bonus-sec .option-toggle span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1d1858;
  font-family: Gilroy;
}
.toggle-btn {
  padding: 2px;
  margin: 0 8px 0 0;
  position: relative;
  width: 36px;
  height: 20px;
  display: block;
  background: #d4d9de;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.35s ease-in-out;
}
.toggle-btn.active {
  background: #4253f0;
}
.toggle-btn:before {
  position: absolute;
  width: 16px;
  height: 16px;
  background: #ffffff;
  box-shadow:
    0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  left: 2px;
  top: 2px;
  content: "";
  border-radius: 100%;
  transition: all 0.35s ease-in-out;
}
.toggle-btn.active:before {
  left: 18px;
  background: #ffffff;
  box-shadow:
    0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
}
.add-bonus-field h6 {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1d1858;
  margin: 0 0 7px;
}
.add-bonus-field {
  padding: 22px 0 0;
}
.bonus-items {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
}
.bonus-items .bouns-field {
  width: calc(50% - 8px);
}
.bonus-items .bouns-field label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #8d98a9;
  display: block;
  margin: 0 0 8px;
}
.bonus-items .bouns-field select {
  padding: 9px 16px;
  width: 100%;
  background: #ffffff url(../images/select-arrow.png) no-repeat center right 15px;
  border: 1px solid #eef0f2;
  border-radius: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1d1858;
  outline: none;
}
.popup-btns-sec.employees-modal {
  position: sticky;
  bottom: 16px;
  padding: 16px 24px !important;
  margin: 0 -24px 16px !important;
  z-index: 100;
  background: white;
}
.popup-btns-sec {
  padding: 24px;
  border-top: 1px solid #eef0f2;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
}
.popup-btns-sec .btn-style {
  width: calc(50% - 8px);
  text-align: center;
  cursor: pointer;
  padding: 7px 16px;
  display: block;
}
.popup-btns-sec .btn-style.blank-btn,
.add-member-form-btn-wrapper .btn-style.blank-btn {
  background: #ffffff;
  border: 1px solid #d4d9de;
  color: #344054;
}
.popup-btns-sec .btn-style.blank-btn:hover,
.add-member-form-btn-wrapper .btn-style.blank-btn:hover {
  background: #4253f0;
  border: solid 1px #4253f0;
  color: #fff;
}

.add-popup-save {
  background: #14bd87;
  border: none;
  color: #e8f7f0;
}

.add-popup-save:hover {
  background: #16d498;
  border: none !important;
  color: #e8f7f0 !important;
}

.employee-btn-group {
  display: flex;
  gap: 16px;
  margin-right: 50px;
}

.add-popup-cancel.employee-btn,
.add-popup-save.employee-btn {
  width: 100px;
}

.add-popup-cancel {
  background: #f5f5f6 !important;
  border: none !important;
  color: #333645 !important;
}

.add-popup-cancel:hover {
  background: #e7e7e9 !important;
  border: none !important;
  color: #333645 !important;
}

.my-profile-save {
  border-radius: 8px;
  height: 36px;
  display: flex;
  align-items: center;
}

.add-member-form-btn-wrapper .btn-style.blank-btn.cancel:hover {
  background: #d4d9de;
  border: 1px solid #d4d9de;
  color: #fff;
}

.bonus-items-remover {
  margin-left: auto;
  text-decoration: none !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #717e94;
}
.form-item ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #717e94 !important;
  opacity: 1;
}
.form-item ::-moz-placeholder {
  /* Firefox 19+ */
  color: #717e94 !important;
  opacity: 1;
}
.form-item :-ms-input-placeholder {
  /* IE 10+ */
  color: #717e94 !important;
  opacity: 1;
}
.form-item :-moz-placeholder {
  /* Firefox 18- */
  color: #717e94 !important;
  opacity: 1;
}

.popup-dis-sec::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 4px;
}

.popup-dis-sec::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.popup-dis-sec::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: #4253f0;
}
button.popup-close {
  padding: 2px 4px;
  background: none !important;
}
button.popup-close:hover {
  background: none !important;
}
button.popup-close img {
  max-width: 20px;
}
div.popup-close img {
  max-width: 20px;
}
#Weekly-data-new,
#basic-info-data {
  display: block;
}

.popup-dis-sec .popup-btns-sec {
  margin: 0 -24px;
  width: calc(100% + 48px);
  padding-bottom: 5px;
}
.popup-content-sec-main .popup-dis-sec {
  padding: 20px 0 25px;
}
.new-employees-basic-info .form-item input {
  padding-left: 16px;
}
.new-employees-basic-info .form-item.percentage input {
  padding-left: 18px;
}

.add-employees-popup .employees-inner > .popup-dis-sec > .popup-tabs > ul {
  border-bottom: 1px solid #eef0f2;
}
.add-employees-popup .employees-inner > .popup-dis-sec > .popup-tabs > ul a {
  background: none !important;
  border: none !important;
  border-bottom: solid 2px #fff !important;
  border-radius: 0 !important;
  padding-left: 12px;
  padding-right: 12px;
}
.add-employees-popup .employees-inner > .popup-dis-sec > .popup-tabs > ul a:hover,
.add-employees-popup .employees-inner > .popup-dis-sec > .popup-tabs > ul a.active {
  border-bottom: solid 2px #4253f0 !important;
}

.employees-popup.add-employees-popup .employees-inner {
  top: 0;
  left: auto;
  transform: none;
  right: 0;
  border-radius: 0;
  max-width: 720px;
  height: 100%;
  max-height: 100%;
  overflow: inherit;
}
.employees-popup.add-employees-popup .employees-inner > .popup-dis-sec {
  height: calc(100% - 64px);
  overflow: auto;
}
.popup-content-sec-main > h5,
.popup-content-sec-main > div > h5 {
  font-weight: 600;
  margin: 0;
}
.employees-popup-profile-sec {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 10px;
}
.margin-bottom-two {
  margin-bottom: 16px !important;
}
.pay-history-item {
  margin-bottom: 16px !important;
  padding-bottom: 10px;
}
.no-pay-history {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pay-history-table table tbody tr {
  height: 50px !important;
}
.pay-history-table table td:nth-child(1) {
  padding-left: 20px !important;
}
.pay-history-table table th:nth-child(1) {
  width: 40%;
}
.pay-history-table {
  padding-right: 0 !important;
  max-height: none !important;
}
.pay-history-table table tr:hover td {
  background: none !important;
}
.pay-history-table table tr:hover {
  cursor: default;
}
.profile-sec span {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #8d98a9;
  margin: 0 0 10px;
}
.add-member {
  display: flex;
  flex-direction: column;
}
.text-align-right {
  text-align: right !important;
}
.payroll-header-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.payroll-header-container .search-box {
  width: 100%;
  margin-left: 20px;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.add-member-form {
  padding: 0 !important;
  border: none !important;
}
.add-member-form .form-item {
  width: 48%;
  margin: 0 0 35px !important;
}
.add-member-form .form-item label {
  text-align: left;
}
.add-member-form-btn-wrapper button {
  width: 200px;
}
.add-member-form-btn-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 24px;
  border-top: 1px solid #eef0f2;
}
.add-member-modal {
  padding: 0 !important;
}
.add-member-modal-form {
  padding: 24px;
}
.add-member-modal-form.add-existing-employee {
  padding: 24px 24px 8px;
}
.add-existing-employee .form-item label {
  text-align: left;
}
.add-member-modal-header {
  height: 64px;
  border-bottom: 1px solid #eef0f2;
}
.add-member-modal-header .title-sec {
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 24px;
}
.add-member-modal-header .title-sec h2 {
  margin: 0;
}
.profile-img {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 0 10px;
}

.profile-img.profile-info {
  position: relative;
}
.profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  overflow: hidden;
}
.profile-sec button img {
  max-width: 20px;
}
.profile-sec button {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #717e94;
  margin: 0;
  background: transparent;
  height: 40px;
  width: 110px;
}
.profile-sec button:hover {
  background: #ebf0fe;
  color: #4253f0;
  border: 1px solid #7b87f5;
  border-radius: 8px;
  cursor: pointer !important;
}
.dsw {
  height: 46px;
}
.employees-profile-img-upload {
  position: relative;
}
.profile-img.profile-info:hover .employees-profile-img-upload.profile-info {
  display: flex !important;
}
.employees-profile-img-upload.profile-info {
  position: absolute;
  top: 0;
  display: none;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.employees-profile-img-upload.profile-info:hover {
  background: rgba(0, 0, 0, 0.4);
}
.employees-profile-img-upload.profile-info img {
  margin: auto;
  width: 20px;
  height: 20px;
}
.employees-profile-img-upload img {
  margin-right: 4px;
}
.fuel-upload-menu-item {
  position: relative;
}
.employees-profile-img-upload input[type="file"],
.fuel-upload-menu-item input[type="file"] {
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.employee-modal-header-buttons {
  display: flex;
  align-items: start;
}

.new-employees-basic-info {
  padding: 20px 0 0;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
}

.new-employees-basic-info::-webkit-scrollbar {
  width: 4px;
  margin-left: 8px;
}

.new-employees-basic-info::-webkit-scrollbar-thumb {
  background-color: #4253f0;
  border-radius: 2px;
}

.new-employees-basic-info::-webkit-scrollbar-thumb:hover {
  background: #5766e7;
  cursor: pointer;
}
.form-items-container {
  display: flex;
  margin-top: 24px;
  gap: 16px;
}
.custom-checkbox-container {
  display: flex;
  align-items: center;
}
.custom-checkbox-container label {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #344054;
  margin: 0;
  cursor: pointer;
  margin-left: 21px;
}
.custom-checkbox-container label.small {
  margin-left: 6px;
  font-size: 12px;
}
.mt-4 {
  margin-top: 32px;
}
.mb-3 {
  margin-bottom: 24px;
}
.mb-2 {
  margin-bottom: 16px;
}
.mb-1 {
  margin-bottom: 8px;
}
.pb-1 {
  padding-bottom: 8px !important;
}
.auto-width {
  width: auto !important;
}
.mt-3 {
  margin-top: 24px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.small-checkbox {
  height: 24px;
  margin-left: -18px;
}
.small-checkbox .MuiSvgIcon-root {
  width: 21px;
  height: 21px;
}
.paychex-checkbox.MuiCheckbox-root {
  padding: 0;
}
.dark-tooltip-text {
  cursor: context-menu;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1d1858;
}
.form-items-container .form-item:nth-child(3) {
  padding-right: 20px;
}
.pr2 {
  padding-right: 20px;
}
.new-employees-basic-info .form-item.full-width {
  width: 100%;
}
.add-work-area-rate .add-bonus-toggle span {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.add-work-area-rate {
  width: 100%;
  display: flex;
  height: 112px;
  margin-bottom: 24px;
}
.add-work-area-vertical-divider {
  height: 112px;
  width: 1px;
  margin: 0 24px;
  background: #eef0f2;
}
.add-work-area-rate-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.add-work-area-add-more {
  justify-content: flex-start;
  display: flex;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #717e94;
}
.add-work-area-add-more svg {
  margin-right: 4px;
}
.add-work-area-add-more:hover svg path {
  stroke: #4253f0;
}
.add-work-area {
  width: 680px;
  padding-bottom: 8px;
  max-height: 80vh;
  overflow: auto;
}
.add-work-area h6 {
  margin-bottom: 12px;
}
.add-work-area label {
  text-align: left;
}
.add-work-area .add-bonus-sec {
  margin-bottom: 16px;
}
.add-work-area .add-bonus-field {
  padding: 24px 0 16px;
}
.add-work-area .add-bonus-field.bonus .form-item-container .form-item {
  width: 45% !important;
}
.add-work-area .add-bonus-field.bonus .form-item-container button {
  margin-top: 16px;
}
.weekly-rate-w-two {
  width: calc(30% - 10px) !important;
}
.work_area_number {
  width: calc(50% - 10px) !important;
  margin-bottom: 24px;
}
.work_area_number label {
  text-align: left;
}
.add-work-area-divider {
  width: 100%;
  height: 1px;
  background-color: #eef0f2;
  margin-bottom: 16px;
}
.new-employees-basic-info .add-bonus-field .form-item {
  width: 31.3%;
}
.new-employees-basic-info .add-bonus-field .form-item.radio {
  width: 62.6%;
}
.new-employees-basic-info .add-bonus-field .form-item.radio.with-delete {
  width: 58.6%;
}
.new-employees-basic-info .add-bonus-field.bonus .form-item {
  width: 45%;
}
.form-item-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.table-sort-arrows-container {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.table-sort-arrows-container .arrow:hover {
  cursor: pointer;
}
.table-sort-arrows-container .arrow:hover path,
.table-sort-arrows-container .arrow.active path {
  opacity: 0.6;
}
.bonus-add-more {
  cursor: pointer;
}
.bonus-add-more:hover {
  color: #4253f0;
}
.new-employees-basic-info .form-item {
  width: calc(50% - 10px);
  margin: 0 0 19px;
}
.new-employees-basic-info .notes-into {
  width: 100%;
  padding: 0 0 25px;
}
.popup-content-sec-main.new-employees-pay-adjustment {
  padding: 0;
  width: 100%;
}
.new-employees-pay-adjustment .popup-dis-sec {
  padding: 0 0 15px;
}
.add-more {
  padding: 12px 0 0;
}
.add-more button {
  text-decoration: none !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #717e94;
  background: transparent;
}
.add-more button span {
  font-size: 20px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}
.per-stop-data-fields {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}
.per-stop-data-fields .form-item {
  width: calc(100% / 3 - 32px / 3);
}
.add-bonus-sec.weekly-salary {
  width: 165px;
  margin-bottom: 0;
}
.add-bonus-sec {
  width: 100%;
  margin-bottom: 20px;
}
.bonus-items .bouns-field:nth-child(2) ~ .bouns-field {
  margin-top: 17px;
}
.add-bonus-sec h6 {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1d1858;
  margin: 0 0 20px;
}
.three-column {
  gap: 16px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
}
.three-column > div {
  width: calc(100% / 3 - 40px / 3);
  margin: 0 0 0 20px !important;
}
.three-column > div:nth-child(3n + 1) {
  margin-left: 0 !important;
}
.three-column > div:nth-child(3) ~ div {
  margin-top: 20px !important;
}
.popup-content-sec .form-item {
  margin: 0 0 20px;
}

.custom-select {
  position: relative;
}

.employees-select.add-existing-employee {
  width: 100%;
}

.employees-select.add-existing-employee.Mui-focused {
  border: 1px solid #7b87f5 !important;
  outline: none !important;
  box-shadow: 0px 0px 0px 4px rgba(64, 139, 250, 0.15) !important;
}

.def-btn-height {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.def-btn-container {
  padding: 16px;
}

.employees-select {
  width: 324px;
  height: 40px;
  border: 1px solid #eef0f2;
  border-radius: 8px;
}
.employees-select .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #1d1858;
}

.employees-select .MuiOutlinedInput-notchedOutline {
  border: none;
}

.employees-select:focus {
  border: 1px solid #7b87f5;
  outline: none;
  box-shadow: 0px 0px 0px 4px rgba(64, 139, 250, 0.15);
}

.select-selected {
  padding: 9px 36px 9px 16px;
  background: #ffffff url(../images/select-arrow-big.png) no-repeat center right 17px;
  width: 100%;
  background-size: 14px;
  border: 1px solid #eef0f2;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1d1858;
}
.select-items {
  padding: 8px;
  position: absolute;
  width: 100%;
  left: 0;
  background: #ffffff;
  border-right: 1px solid #eef0f2;
  box-shadow: 10px 20px 60px rgba(32, 32, 32, 0.08);
  border-radius: 8px;
  top: 100%;
  margin-top: 5px;
  z-index: 9;
}
.select-items.select-hide {
  display: none;
}
.select-items div {
  padding: 12px 16px;
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #717e94;
}
.select-items div:hover,
.select-items div.same-as-selected {
  background: #f6f6f6;
}

.organization-table-row-btns {
  min-height: 40px;
  display: flex;
  align-items: center;
}

.payroll-top {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: 0 0 20px;
}
.payroll-top .payroll-result-count {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 500;
  width: 40%;
}
.pointer {
  cursor: pointer;
}

.loader-wrapper {
  height: 50vh;
}

.MuiModal-root.MuiMenu-root.MuiPopover-root {
  z-index: 10000;
}

#menu-.MuiModal-root.MuiMenu-root.MuiPopover-root {
  margin-top: 8px;
}

#menu-.MuiModal-root.MuiMenu-root.MuiPopover-root .MuiPaper-root.MuiPaper-elevation {
  border-radius: 8px;
  box-shadow: 0px 10px 30px 0px #3336451a;
}

#menu-.MuiModal-root.MuiMenu-root.MuiPopover-root
  .MuiPaper-root.MuiPaper-elevation
  ul
  .Mui-selected {
  background-color: #ebf0fe;
}

.search-box.leaderboard {
  margin-left: auto;
  margin-right: 8px;
  width: 33%;
}

@media screen and (max-width: 660px) {
  .trial-wrapper {
    justify-content: start;
    padding: 0 8px;
  }
}

@media screen and (max-width: 572px) {
  .trial-wrapper {
    height: 64px;
  }
  .trial-button {
    margin-left: 3px;
  }
}

@media screen and (max-width: 443px) {
  .trial-wrapper {
    height: 84px;
  }
}

@media screen and (max-width: 1360px) {
  .search-box.leaderboard {
    margin-left: 19rem;
    margin-right: 0;
    width: 33%;
  }
}

@media screen and (max-width: 760px) {
  .search-box.leaderboard {
    margin-left: 0 !important;
    width: 100% !important;
  }

  .top-title-bar .title-sec {
    white-space: normal;
  }
}

@media screen and (max-width: 694px) {
  .search-box.leaderboard {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 990px) {
  .sidebar-sec:hover .sidebar-bottom-sec-collapse {
    display: flex;
  }
}
@media screen and (max-width: 990px) {
  .header-sec-bread-crumbs {
    display: none;
  }
}

/*Responsive*/
@media screen and (max-width: 1400px) {
}
@media screen and (max-width: 1199px) {
  .track img {
    margin: 0 25px;
  }
  .sidebar-sec {
    width: 260px;
    padding: 0 15px;
  }
  .content-sec {
    width: calc(100% - 260px);
    padding: 0 15px 50px;
  }
  .header-sec {
    padding: 15px 15px;
    width: calc(100% + 30px);
    margin: 0 -15px;
  }
  .employees-data-sec {
    padding: 15px;
  }
  .employees-data-sec table td {
    padding: 15px 10px;
  }
  .employees-data-sec table th {
    padding: 12px 10px;
  }
  .employees-data-sec table th:last-child {
    width: 50px;
  }
  .employees-data-sec table th:nth-child(1) {
    padding: 12px 10px 12px 15px;
  }
  .employees-data-sec table td:nth-child(1) img {
    left: 15px;
  }
  .employees-data-sec table td:nth-child(1) {
    padding: 17px 10px 17px 60px;
  }
}
@media screen and (max-width: 991px) {
  .employees-data-sec.organizations > .payroll-top > .search-box,
  .employees-data-sec.organizations > .payroll-top > .payroll-result-count {
    width: 100%;
  }
  .css-1q8dea4-MuiPagination-root .MuiPagination-ul li:last-child {
    width: 40px;
  }
  .sign-up-form-wrapper {
    padding: 80px 0 35px;
  }
  .employees-inner {
    width: 100%;
    max-width: none;
  }
  .payroll-table.employees {
    overflow: auto !important;
  }
  .payroll-top {
    flex-wrap: wrap;
  }
  .payroll-header-container {
    flex-wrap: wrap;
    width: auto;
  }
  .payroll-header-container .search-box {
    min-width: 300px;
  }
  .confirm-modal,
  .confirm-modal.csa {
    width: 100% !important;
    min-width: 100% !important;
  }
  .add-member-modal-form {
    max-height: 300px;
    overflow: auto;
  }
  .settings-expenses-content-item {
    min-width: 500px;
  }
  .add-work-area-vertical-divider {
    display: none;
  }
  .add-work-area-rate {
    flex-direction: column;
    height: auto;
  }
  .add-work-area {
    width: auto;
  }
  .add-work-area-rate-container,
  .auto-clock-in-container {
    width: 100% !important;
  }
  .settings-applications-company-row {
    overflow: auto;
  }
  .applications-company-row-item {
    width: 600px !important;
  }
  .header-menu-wrapper.notifications .MuiPaper-root {
    margin-top: 16px;
    margin-left: 0;
  }
  .header-menu-wrapper.notifications .MuiPaper-root .MuiList-root {
    width: 350px;
  }
  .notifications-menu-item-text {
    max-width: 254px;
  }
  .my-profile-dis.profile-info {
    padding-left: 8px !important;
  }
  .profile-form-sec.password {
    margin-right: 28px;
  }
  .subscription-plan-container {
    flex-direction: column;
  }
  .header-sec {
    justify-content: flex-end !important;
  }
  .login-left-sec {
    padding: 100px 20px 35px 0;
  }
  .login-right-sec {
    padding: 100px 20px 95px;
  }
  .login-options button {
    width: 100%;
    margin: 0 auto 0;
  }
  .login-options button + button {
    margin: 10px auto 0;
  }
  .login-left-sec h1 {
    font-size: 28px;
  }
  .login-right-sec h2 {
    font-size: 28px;
    line-height: 36px;
  }
  .track img {
    margin: 0 20px;
  }
  .sidebar-sec {
    width: 100%;
    height: auto;
    z-index: 9;
    min-height: auto;
    overflow: inherit;
    position: relative;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid #eef0f2;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
  }
  .content-sec {
    width: 100%;
    padding: 0 15px 50px;
    overflow: inherit;
    height: auto;
  }
  .sidebar-menus-sec {
    height: auto;
    /* display: none; */
    position: absolute;
    left: 0;
    top: 71px;
    background: #fff;
    width: 100%;
    padding: 20px 15px 20px;
    box-shadow: 0px 10px 13px rgba(0, 0, 0, 0.1);
  }
  .mobile-menu-bars {
    display: none;
  }
  .main-logo {
    padding: 16px 0 16px 57px;
    position: relative;
  }
  .main-logo .logo-item {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .main-logo h6 {
    margin: 0px 0 2px;
  }
  .mobile-menu-bars {
    margin-right: auto;
    display: block;
    position: relative;
    width: 25px;
    height: 25px;
    padding: 4px 0;
    cursor: pointer;
  }
  .mobile-menu-bars span {
    display: block;
    width: 25px;
    height: 2px;
    background: #1d1858;
    margin: 0;
    transition: all 0.35s ease-in-out;
    position: relative;
  }
  .mobile-menu-bars span.line-2 {
    margin: 6px 0;
  }

  .mobile-menu-bars.menu-show span.line-2 {
    opacity: 0;
  }
  .mobile-menu-bars.menu-show span.line-1 {
    transform: rotate(45deg);
    position: relative;
    top: 8px;
    left: 1px;
  }
  .mobile-menu-bars.menu-show span.line-3 {
    transform: rotate(-45deg);
    position: relative;
    top: -8px;
    left: 1px;
  }
  .sidebar-menus h6 {
    padding-bottom: 0;
  }
  .btn-style {
    padding: 8px 15px;
  }
}
@media screen and (max-width: 767px) {
  .login-left-sec {
    padding: 80px 0 35px;
    width: 100%;
  }
  .login-right-sec {
    padding: 60px 20px 45px;
    width: 100%;
  }
  .login-marquee {
    margin: 40px 0 0;
  }
  .copyright-sec p {
    margin: 0;
  }
  .copyright-sec {
    position: relative;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    text-align: center;
    padding: 28px 0 0;
  }
  .login-right-sec h2 {
    font-size: 25px;
    line-height: 30px;
  }
  .login-right-sec p {
    margin: 0 0 35px;
  }
  .login-left-sec h1 {
    font-size: 25px;
  }
  .login-logo-button {
    background: transparent;
  }
  .login-logo {
    position: absolute;
    left: 0;
    padding: 0 20px;
    top: 20px;
    z-index: 2;
    width: 100%;
    text-align: center;
  }
  form.login-form .input-items {
    margin: 0 0 18px;
  }
  .table-scroll-mobile {
    overflow: auto;
  }
  .header-box-r button.user-btn {
    margin-left: 15px;
  }
  .top-title-bar .title-sec {
    width: 100%;
    padding-bottom: 15px;
  }
  .top-title-bar .btn-sec {
    width: 100%;
    justify-content: flex-start;
    gap: 12px;
  }
  .btn-sec .btn-style {
    margin: 0 10px 10px 0;
  }
  .employees-data-sec table {
    width: 100%;
    min-width: 670px;
  }
  .pagination ul li {
    margin: 0 4px 4px;
  }
  .pagination ul {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
  }
  .popup-tabs ul li a {
    padding: 9px 15px;
  }
  .popup-tabs ul li {
    margin: 0 10px 10px 0 !important;
  }
  .per-stop-data-fields .form-item,
  .new-employees-basic-info .form-item {
    width: 100%;
  }
  .three-column > div {
    width: 100%;
    margin: 0 0 20px !important;
  }
  .bonus-items .bouns-field:nth-child(2) ~ .bouns-field {
    margin-top: 0;
  }
  .bonus-items .bouns-field {
    width: 100%;
    margin: 0 0 20px;
  }
}
.have-account-sec-wrapper {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 580px) {
  .btn-style {
    padding: 8px 13px;
    min-width: auto !important;
  }
}
@media screen and (max-width: 470px) {
  .popup-btns-sec.employees-modal .employee-more-info {
    max-width: 300px;
    display: block;
  }
}

/* Employee Report Profile (Mobile) */
.run-payroll-data.statement.employee-report-profile.mobile {
  .payroll-header > .title > h4 {
    font-size: 14px;
  }

  .payroll-table::-webkit-scrollbar {
    height: 10px;
  }
}
