// general styles

.mr1 {
    margin-right: 8px;
}
.mt1 {
    margin-top: 8px;
}
.half-width {
    width: 50%;
}
.confirm-modal-button-wrapper {
    gap: 12px;
    justify-content: space-between;
}
.onboard-form{
    max-width: 480px;
    margin: 0 auto;
    margin-top: 30px;
}
